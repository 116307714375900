export const EmojiDoc = [
    {
        emoji: '🙂', num: 9, color: "rgb(245, 230, 100)",
        anger: 1,
        fear: 1,
        sadness: 1,
        disgust: 1,
        surprise: 1,
        anticipation: 1,
        trust: 1,
        joy: 3,
        activity: 1,
    }, {
        emoji: '😀', num: 1, color: "rgb(245, 225, 120)",
        anger: 0, fear: 0, sadness: 0,
        disgust: 0, surprise: 0, anticipation: 0,
        trust: 2, joy: 8, activity: 3
    }, {
        emoji: '😃', num: 2, color: "rgb(255, 245, 150)",
        anger: 0, fear: 0, sadness: 0,
        disgust: 0, surprise: 0, anticipation: 0,
        trust: 2, joy: 8, activity: 4,
    }, {
        emoji: '😄', num: 3, color: "rgb(232, 174, 2)",
        anger: 0, fear: 0, sadness: 0,
        disgust: 0, surprise: 0, anticipation: 0,
        trust: 2, joy: 8, activity: 5,
    }, {
        emoji: '😁', num: 4, color: "rgb(245, 194, 1)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 2,
        joy: 8,
        activity: 5,
    }, {
        emoji: '😆', num: 5, color: "rgb(245, 166, 0)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 10,
        activity: 7,
    }, {

        emoji: '😉', num: 11, color: "rgb(255, 245, 150)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 1,
        trust: 8,
        joy: 1,
        activity: 3,
    }, {
        emoji: '😊', num: 12, color: "rgb(235, 140, 120)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 5,
        joy: 5,
        activity: 4,
    }, {
        emoji: '😇', num: 13, color: "rgb(15, 176, 235)",
        anger: 2,
        fear: 0,
        sadness: 6,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 2,
        activity: 10,
    }, {

        emoji: '🥰', num: 14, color: "rgb(245, 100, 120)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 2,
        joy: 8,
        activity: 9,
    }, {
        emoji: '😍', num: 15, color: "rgb(235, 100, 80)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 10,
        activity: 10,
    }, {
        emoji: '🤩', num: 16, color: "rgb(255, 200, 50)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 5,
        trust: 5,
        joy: 0,
        activity: 9,
    }, {
        emoji: '😘', num: 17, color: "rgb(245, 133, 130)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 1,
        trust: 6,
        joy: 3,
        activity: 5,
    }, {
        emoji: '😗', num: 18, color: "rgb(245, 177, 80)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 5,
        trust: 0,
        joy: 5,
        activity: 2,
    }, {
        emoji: '😚', num: 19, color: "rgb(245, 130, 100)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 1,
        trust: 4,
        joy: 5,
        activity: 2,
    }, {
        emoji: '😙', num: 20, color: "rgb(245, 188, 100)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 2,
        trust: 0,
        joy: 8,
        activity: 2,
    }, {
        emoji: '😋', num: 22, color: "rgb(235, 130, 100)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 4,
        trust: 0,
        joy: 6,
        activity: 6,
    }, {
        emoji: '😛', num: 23, color: "rgb(235, 100, 127)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 10,
        activity: 7,
    }, {
        emoji: '😜', num: 24, color: "rgb(235, 100, 127)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 10,
        activity: 8,
    }, {
        emoji: '🤪', num: 25, color: "rgb(235, 100, 176)",
        anger: 2,
        fear: 0,
        sadness: 0,
        disgust: 3,
        surprise: 3,
        anticipation: 0,
        trust: 0,
        joy: 2,
        activity: 10,
    }, {
        emoji: '😝', num: 26, color: "rgb(235, 100, 127)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 10,
        activity: 8,
    }, {
        emoji: '🤑', num: 27, color: "rgb(100, 219, 150)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 7,
        trust: 0,
        joy: 3,
        activity: 7,
    }, {
        emoji: '🤤', num: 46, color: "rgb(41, 212, 235)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 9,
        trust: 0,
        joy: 1,
        activity: 4,
    }, {
        emoji: '🥴', num: 56, color: "rgb(235, 100, 90)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 10,
        trust: 0,
        joy: 0,
        activity: 3,
    }, {
        emoji: '😌', num: 43, color: "rgb(245, 108, 104)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 10,
        joy: 0,
        activity: 2,
    }, {
        emoji: '🤗', num: 28, color: "rgb(235, 111, 38)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 8,
        joy: 2,
        activity: 7,
    }, {
        emoji: '🤭', num: 29, color: "rgb(235, 150, 150)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 8,
        joy: 2,
        activity: 6,
    }, {
        emoji: '🤠', num: 60, color: "rgb(180, 132, 66)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 10,
        activity: 6,
    }, {
        emoji: '🥳', num: 61, color: "rgb(230, 120, 230)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 10,
        activity: 7,
    }, {
        //     emoji: '🥸', num: 62, color: "rgb(188, 119, 235)",
        //     anger: 0,
        //     fear: 0,
        //     sadness: 0,
        //     disgust: 0,
        //     surprise: 0,
        //     anticipation: 10,
        //     trust: 0,
        //     joy: 0,
        //     activity: 2,
        // }, {
        emoji: '😎', num: 63, color: "rgb(160, 200, 255)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 3,
        trust: 3,
        joy: 4,
        activity: 3,
    }, {
        emoji: '🤓', num: 64, color: "rgb(118, 209, 203)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 10,
        joy: 0,
        activity: 3,
    }, {
        emoji: '🧐', num: 65, color: "rgb(164, 172, 209)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 5,
        anticipation: 3,
        trust: 2,
        joy: 0,
        activity: 2,
    }, {
        emoji: '🤔', num: 31, color: "rgb(235, 143, 84)",
        anger: 1,
        fear: 0,
        sadness: 0,
        disgust: 2,
        surprise: 2,
        anticipation: 5,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '🤨', num: 33, color: "rgb(235, 173, 157)",
        anger: 4,
        fear: 0,
        sadness: 0,
        disgust: 2,
        surprise: 4,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 4,
    }, {
        emoji: '😐', num: 34, color: "rgb(255, 220, 85)",
        anger: 2,
        fear: 1,
        sadness: 1,
        disgust: 1,
        surprise: 3,
        anticipation: 0,
        trust: 1,
        joy: 1,
        activity: 1,
    }, {
        emoji: '😑', num: 35, color: "rgb(255, 220, 85)",
        anger: 8,
        fear: 0,
        sadness: 0,
        disgust: 2,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '😶', num: 36, color: "rgb(255, 220, 85)",
        anger: 1,
        fear: 1,
        sadness: 1,
        disgust: 1,
        surprise: 3,
        anticipation: 1,
        trust: 1,
        joy: 1,
        activity: 1,
    }, {
        emoji: '🙃', num: 10, color: "rgb(255, 200, 150)",
        anger: 2,
        fear: 1,
        sadness: 1,
        disgust: 1,
        surprise: 1,
        anticipation: 1,
        trust: 1,
        joy: 2,
        activity: 5,
    }, {
        emoji: '😏', num: 37, color: "rgb(242, 207, 255)",
        anger: 3,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 5,
        trust: 2,
        joy: 3,
        activity: 3,
    }, {
        emoji: '😒', num: 38, color: "rgb(255, 171, 154)",
        anger: 3,
        fear: 0,
        sadness: 0,
        disgust: 7,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '🙄', num: 39, color: "rgb(190, 245, 236)",
        anger: 2,
        fear: 2,
        sadness: 0,
        disgust: 3,
        surprise: 3,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 5,
    }, {
        emoji: '🤫', num: 30, color: "rgb(255, 200, 150)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 10,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        //     emoji: '🫣', num: 94, color: "rgb(255, 177, 106)",
        //     anger: 0,
        //     fear: 5,
        //     sadness: 0,
        //     disgust: 0,
        //     surprise: 5,
        //     anticipation: 0,
        //     trust: 0,
        //     joy: 0,
        //     activity: 7,
        // }, {
        emoji: '🤐', num: 32, color: "rgb(105, 180, 141)",
        anger: 4,
        fear: 4,
        sadness: 0,
        disgust: 2,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '🤥', num: 42, color: "rgb(230, 200, 100)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 10,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 3,
    }, {
        //     emoji: '😮‍💨', num: 41, color: "rgb(80, 177, 184)",
        //     anger: 3,
        //     fear: 0,
        //     sadness: 4,
        //     disgust: 0,
        //     surprise: 0,
        //     anticipation: 0,
        //     trust: 3,
        //     joy: 0,
        //     activity: 3,
        // }, {
        emoji: '🥱', num: 88, color: "rgb(166, 190, 240)",
        anger: 8,
        fear: 0,
        sadness: 0,
        disgust: 1,
        surprise: 0,
        anticipation: 3,
        trust: 3,
        joy: 3,
        activity: 5,
    }, {
        emoji: '😔', num: 44, color: "rgb(224, 200, 100)",
        anger: 0,
        fear: 0,
        sadness: 9,
        disgust: 1,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '😪', num: 45, color: "rgb(150, 200, 255)",
        anger: 0,
        fear: 0,
        sadness: 1,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 9,
        joy: 0,
        activity: 1,
    }, {
        emoji: '😴', num: 47, color: "rgb(190, 180, 255)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 10,
        joy: 0,
        activity: 2,
    }, {
        //     emoji: '🫥', num: 95, color: "rgb(200, 215, 240)",
        //     anger: 0,
        //     fear: 1,
        //     sadness: 0,
        //     disgust: 3,
        //     surprise: 3,
        //     anticipation: 3,
        //     trust: 0,
        //     joy: 0,
        //     activity: 1,
        // }, {
        //     emoji: '😶‍🌫️', num: 96, color: "rgb(210, 235, 237)",
        //     anger: 0,
        //     fear: 10,
        //     sadness: 0,
        //     disgust: 0,
        //     surprise: 0,
        //     anticipation: 0,
        //     trust: 0,
        //     joy: 0,
        //     activity: 1,
        // }, {
        //     emoji: '🫠', num: 97, color: "rgb(232, 175, 16)",
        //     anger: 0,
        //     fear: 0,
        //     sadness: 8,
        //     disgust: 0,
        //     surprise: 0,
        //     anticipation: 0,
        //     trust: 0,
        //     joy: 2,
        //     activity: 2,
        // }, {
        //     emoji: '🫤', num: 99, color: "rgb(255, 254, 226)",
        //     anger: 2,
        //     fear: 0,
        //     sadness: 3,
        //     disgust: 5,
        //     surprise: 0,
        //     anticipation: 0,
        //     trust: 0,
        //     joy: 0,
        //     activity: 2,
        // }, {
        emoji: '😕', num: 66, color: "rgb(245, 225, 120)",
        anger: 2,
        fear: 0,
        sadness: 4,
        disgust: 0,
        surprise: 4,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 1,
    }, {
        emoji: '😟', num: 67, color: "rgb(235, 183, 131)",
        anger: 0,
        fear: 0,
        sadness: 7,
        disgust: 0,
        surprise: 3,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '🙁', num: 68, color: "rgb(245, 225, 120)",
        anger: 0,
        fear: 0,
        sadness: 10,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 1,
    }, {
        emoji: '☹️', num: 98, color: "rgb(245, 225, 120)",
        anger: 0,
        fear: 0,
        sadness: 5,
        disgust: 5,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '😮', num: 69, color: "rgb(235, 130, 130)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 10,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 1,
    }, {
        emoji: '😯', num: 70, color: "rgb(235, 185, 90)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 10,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '😲', num: 71, color: "rgb(205, 120, 90)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 10,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 3,
    }, {
        emoji: '😳', num: 72, color: "rgb(255, 180, 160)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 10,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 6,
    }, {
        emoji: '😦', num: 74, color: "rgb(200, 110, 120)",
        anger: 0,
        fear: 0,
        sadness: 2,
        disgust: 0,
        surprise: 8,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '😧', num: 75, color: "rgb(200, 110, 120)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 10,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '😅', num: 6, color: "rgb(150, 200, 235)",
        anger: 0,
        fear: 1,
        sadness: 1,
        disgust: 0,
        surprise: 6,
        anticipation: 0,
        trust: 0,
        joy: 2,
        activity: 3,
    }, {
        emoji: '😓', num: 85, color: "rgb(150, 200, 235)",
        anger: 0,
        fear: 0,
        sadness: 3,
        disgust: 0,
        surprise: 7,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '😥', num: 78, color: "rgb(150, 200, 235)",
        anger: 0,
        fear: 0,
        sadness: 8,
        disgust: 0,
        surprise: 2,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '😨', num: 76, color: "rgb(120, 125, 220)",
        anger: 0,
        fear: 8,
        sadness: 0,
        disgust: 2,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 3,
    }, {
        emoji: '😰', num: 77, color: "rgb(170, 150, 255)",
        anger: 0,
        fear: 5,
        sadness: 0,
        disgust: 5,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 3,
    }, {
        //     emoji: '🥲', num: 21, color: "rgb(50, 200, 210)",
        //     anger: 0,
        //     fear: 0,
        //     sadness: 10,
        //     disgust: 0,
        //     surprise: 0,
        //     anticipation: 0,
        //     trust: 0,
        //     joy: 0,
        //     activity: 4,
        // }, {
        //     emoji: '🥹', num: 100, color: "rgb(140, 200, 250)",
        //     anger: 0,
        //     fear: 0,
        //     sadness: 5,
        //     disgust: 0,
        //     surprise: 0,
        //     anticipation: 0,
        //     trust: 0,
        //     joy: 5,
        //     activity: 5,
        // }, {
        emoji: '🥺', num: 73, color: "rgb(150, 220, 255)",
        anger: 0,
        fear: 0,
        sadness: 8,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 2,
        activity: 3,
    }, {
        emoji: '😢', num: 79, color: "rgb(100, 180, 240)",
        anger: 0,
        fear: 0,
        sadness: 10,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 3,
    }, {
        emoji: '😭', num: 80, color: "rgb(90, 190, 240)",
        anger: 0,
        fear: 0,
        sadness: 10,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 7,
    }, {
        emoji: '🤣', num: 7, color: "rgb(180, 200, 255)",
        anger: 0,
        fear: 0,
        sadness: 3,
        disgust: 0,
        surprise: 2,
        anticipation: 0,
        trust: 0,
        joy: 5,
        activity: 9,
    }, {
        emoji: '😂', num: 8, color: "rgb(100, 220, 245)",
        anger: 0,
        fear: 0,
        sadness: 5,
        disgust: 0,
        surprise: 2,
        anticipation: 0,
        trust: 0,
        joy: 3,
        activity: 8,
    }, {
        emoji: '😱', num: 81, color: "rgb(140, 130, 240)",
        anger: 0,
        fear: 10,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 10,
    }, {
        emoji: '😖', num: 82, color: "rgb(224, 170, 120)",
        anger: 0,
        fear: 0,
        sadness: 5,
        disgust: 5,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 3,
    }, {
        emoji: '😣', num: 83, color: "rgb(224, 170, 120)",
        anger: 0,
        fear: 0,
        sadness: 2,
        disgust: 0,
        surprise: 8,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 3,
    }, {
        emoji: '😞', num: 84, color: "rgb(240, 190, 12)",
        anger: 0,
        fear: 0,
        sadness: 10,
        disgust: 0,
        surprise: 8,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '😩', num: 86, color: "rgb(240, 255, 185)",
        anger: 8,
        fear: 0,
        sadness: 2,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 7,
    }, {
        emoji: '😫', num: 87, color: "rgb(240, 255, 185)",
        anger: 10,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 7,
    }, {
        emoji: '😤', num: 89, color: "rgb(151, 188, 222)",
        anger: 5,
        fear: 0,
        sadness: 2,
        disgust: 0,
        surprise: 0,
        anticipation: 3,
        trust: 2,
        joy: 0,
        activity: 7,
    }, {
        emoji: '😠', num: 91, color: "rgb(240, 200, 60)",
        anger: 10,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 8,
    }, {
        emoji: '😡', num: 90, color: "rgb(255, 150, 170)",
        anger: 10,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 10,
    }, {
        emoji: '🤬', num: 92, color: "rgb(255, 150, 170)",
        anger: 10,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 10,
    }, {
        emoji: '🥵', num: 54, color: "rgb(214, 70, 100)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 3,
        surprise: 7,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 7,
    }, {
        emoji: '😬', num: 40, color: "rgb(160, 230, 223)",
        anger: 4,
        fear: 2,
        sadness: 0,
        disgust: 0,
        surprise: 3,
        anticipation: 0,
        trust: 0,
        joy: 1,
        activity: 2,
    }, {
        emoji: '🥶', num: 55, color: "rgb(120, 170, 255)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 10,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 6,
    }, {
        emoji: '😷', num: 48, color: "rgb(166, 224, 255)",
        anger: 0,
        fear: 0,
        sadness: 7,
        disgust: 1,
        surprise: 1,
        anticipation: 0,
        trust: 1,
        joy: 0,
        activity: 1,
    }, {
        emoji: '🤒', num: 49, color: "rgb(255, 170, 157)",
        anger: 0,
        fear: 0,
        sadness: 8,
        disgust: 0,
        surprise: 2,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 3,
    }, {
        emoji: '🤕', num: 50, color: "rgb(227, 238, 225)",
        anger: 0,
        fear: 0,
        sadness: 10,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        emoji: '🤧', num: 53, color: "rgb(240, 226, 204)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 3,
        surprise: 7,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 4,
    }, {
        emoji: '😵', num: 57, color: "rgb(240, 255, 26)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 10,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 2,
    }, {
        //     emoji: '😵‍💫', num: 58, color: "rgb(245, 170, 100)",
        //     anger: 0,
        //     fear: 0,
        //     sadness: 0,
        //     disgust: 0,
        //     surprise: 10,
        //     anticipation: 0,
        //     trust: 0,
        //     joy: 0,
        //     activity: 7,
        // }, {
        emoji: '🤢', num: 51, color: "rgb(160, 220, 100)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 10,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 7,
    }, {
        emoji: '🤮', num: 52, color: "rgb(150, 236, 150)",
        anger: 0,
        fear: 0,
        sadness: 0,
        disgust: 10,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 8,
    }, {
        emoji: '🤯', num: 59, color: "rgb(255, 170, 157)",
        anger: 10,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 0,
        activity: 10,
    }, {
        emoji: '😈', num: 93, color: "rgb(195, 100, 210)",
        anger: 5,
        fear: 0,
        sadness: 0,
        disgust: 0,
        surprise: 0,
        anticipation: 0,
        trust: 0,
        joy: 5,
        activity: 7,
    }
]